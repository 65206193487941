#learnMoreWrapper {
  position: relative;
  display: none;
  width: 80%;
  padding: 20px 5px;
  margin-top: 20px;
  border: 2px solid $primary;
  color: $primary;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: width 0.6s;
    background-color: $primary;
  }
  &:hover {
    & .iconWrap {
      &:after {
        width: 120%;
        height: 120%;
      }
      span {
        color: $light;
      }
    }
  }
  &.selected {
    .iconWrap {
      span {
        color: $light;
        &:before {
          content: "c";
          font-size: 0.9em;
        }
      }
    }
    .toolTip {
      opacity: 0;
    }
    &:before {
      width: 100%;
    }
  }
  .iconWrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 65px;
    font-size: 2em;
    overflow: hidden;
    span {
      display: block;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color 0.3s;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      transition: width 0.5s, height 0.5s;
      background-color: $primary;
    }
  }
  .toolTip {
    margin-left: 70px;
  }
  p {
    font-family: $primaryFont;
    margin: 0 !important;
    line-height: 1.4em !important;
  }
  .icon {}
}
.lmNote {
  position: absolute;
  z-index: 10;
  line-height: 1.4em;
  &.show {
    top: 50%;
    transform: translateY(-50%);
    margin-left: 70px;
    margin-right: 10px;
    color: $light;
  }
}
.learnMoreSide {
  padding: 22px 35px 35px;
  margin-top: 10px;
  border: 2px solid $primary;
  background-color: $light;
  .title {
    position: relative;
    display: inline-block;
    margin-top: 22px;
    margin-bottom: 20px;
    color: $primary;
    font-size: 1.8em;
    font-family: $secondaryFont;
    font-weight: 700;
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: -11px;
      width: 45%;
      height: 3px;
      background-color: #d9a762;
    }
  }
  #learnMoreWrapper {
    margin: 0;
    width: 100%;
  }
}
.learnMore {
  .learnMoreLinksWrap {
    @include clearfix;
    display: flex;
    justify-content: space-between;
  }
  .slideTitle {
    text-align: center;
  }
  .learnMoreContent {
    padding: 45px 50px 50px;
    background: $light;
  }
  .linkGroup {
    font-family: $basicFont;
    padding: 25px 0 30px;
    color: $primary;
    flex-basis: 100%;
    &.default {
      color: $primary;
      .learnMoreLinks {}
    }
    &.personalized {
      background: $light;
      padding-bottom: $footerHeight + 30;
    }
    h4 {
      text-align: center;
      font-size: 1.3em;
      margin-top: 20px;
      color: $basic;
    }
    h3 {
      font-family: $secondaryFont;
      color: $primary;
      margin: 20px 0 10px;
      font-size: 1.5em;
      text-align: center;
    }
    p {
      text-align: center;
      color: $basic;
    }
  }
  .learnMoreLinks {
    list-style-type: none;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 575px;
    border-bottom: 0 !important;
    li {
      display: block;
      font-weight: 400;
      margin-bottom: 10px;
      &::before {
        display: none;
      }
      a {
        display: block;
        padding: 10px 0 10px 55px;
        text-decoration: none;
        font-size: 1.3em;
        color: $primary;
        position: relative;
        text-decoration: none !important;
        border-bottom: 1px $grey solid;
        span {
          font-size: 0.85em;
          text-transform: uppercase;
        }
        &:hover {
          background: $primary;
          color: $light;
          //border-color: $alt;
          &:before {
            color: $light;
          }
        }
        &:before {
          @include iconFont;
          content: "r";
          float: left;
          display: block;
          position: absolute;
          left: 20px;
          top: 15px;
          font-size: 1em;
          color: $primary;
        }
        &[href*='.pdf'] {
          &::before {
            content: "p";
            font-size: 1em;
          }
        }
      }
    }
  }
}
.orientationComplete {
  .learnMore {
    .linkGroup {
      &.default {
        width: 35%;
        float: left;
      }
      &.personalized {
        display: block;
        width: 64%;
        float: right;
      }
    }
  }
}
@media (max-width: $lg) {
  .learnMoreSide {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
@media (max-width: $md) {
  #learnMoreWrapper {
    margin-top: 20px;
  }
  .learnMore {
    .learnMoreLinks {
      li {
        padding-left: 0;
      }
    }
    .linkGroup {
      p {
        padding: 5px;
      }
    }
  }
  .orientationComplete {
    .learnMore {
      .linkGroup {
        &.default {
          width: 100%;
          float: none;
        }
        &.personalized {
          display: block;
          width: 100%;
          float: none;
        }
      }
    }
  }
}
@media (max-width: $lg) {
  .learnMore {
    .learnMoreLinksWrap {
      display: block;
    }
    .linkGroup {
      width: 80% !important;
      float: none !important;
      margin: 0 auto;
    }
  }
}
@media (max-width: $sm) {
  .learnMore {
    .learnMoreLinksWrap {
      display: block;
    }
    .linkGroup {
      width: 95% !important;
      float: none !important;
      margin: 0 auto;
    }
  }
}
@media (max-width: $xs) {
  #learnMoreWrapper {
    .iconWrap {
      width: 45px;
    }
    .toolTip {
      margin-left: 53px;
    }
    .lmNote.show {
      margin-left: 48px;
    }
  }
  .learnMoreSide {
    padding: 15px 20px 20px;
  }
  .learnMore .learnMoreLinks li a {
    font-size: 1.15em;
    padding: 5px 0 10px 35px;
    &::before {
      left: 10px;
    }
  }
  .learnMore {
    .learnMoreContent {
      padding: 20px 0px 40px;
    }
    .linkGroup {
      width: 100% !important;
      padding: 0px 0 30px;
    }
    .linkGroup h3 {
    font-size: 1.25em;
  }
  }
}
@media (max-width: $xxs) {
  .learnMoreSide {
    padding: 10px 15px 20px;
  }
}
