#homeScreen {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  position: fixed;
  top: $headerHeight;
  left: 0;
  width: 100%;
  height: calc(100% - #{$headerHeight});
  overflow-x: hidden;
  padding: 0 80px 80px;
  animation: none;
  .transitionOut & {
    animation: homeScreen_transitionOut 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
    top: 350px;
    opacity: 0;
  }
  .transitionIn & {
    animation: homeScreen_transitionIn 0.5s;
    animation-fill-mode: backwards;
    top: 150px;
    opacity: 1;
  }
  .inSection & {
    top: 350px;
    opacity: 0;
    display: none;
  }
}
@media (max-width: $xl) {
  #homeScreen {
    height: calc(100% - 120px);
    top: 120px;
    .transitionOut & {
      animation: homeScreen_transitionOut_xl 0.5s;
      animation-delay: 0.6s;
      animation-fill-mode: backwards;
      top: 320px;
      opacity: 0;
    }
    .transitionIn & {
      animation: homeScreen_transitionIn_xl 0.5s;
      animation-fill-mode: backwards;
      top: 120px;
      opacity: 1;
    }
    .inSection & {
      top: 320px;
      opacity: 0;
      display: none;
    }
  }
}
@media (max-width: $lg) {
  #homeScreen {
    padding: 0 0 80px;
  }
}
@media (max-width: $md) {
  #homeScreen {
    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 110px;
      left: 50px;
      height: 62px;
      width: 25px;
      z-index: 99;
      background: linear-gradient(to right, rgba(36,39,41,1) 0%,rgba(36,39,41,1) 40%,rgba(36,39,41,0) 100%);
      pointer-events: none;
    }
    &:after {
      content: "";
      display: block;
      position: fixed;
      top: 110px;
      right: 0;
      height: 62px;
      width: 80px;
      z-index: 99;
      background: linear-gradient(to right, rgba(36,39,41,0) 0%,rgba(36,39,41,0.7) 60%,rgba(36,39,41,1) 100%);
      pointer-events: none;
    }
  }
}
@media (max-width: $sm) {
  #homeScreen {
    top: 90px;
    height: calc(100% - 90px);
    &:before {
      top: 90px;
    }
    &:after {
      top: 90px;
    }
    .transitionOut & {
      animation: homeScreen_transitionOut_sm 0.5s;
      animation-delay: 0.6s;
      animation-fill-mode: backwards;
      top: 320px;
      opacity: 0;
    }
    .transitionIn & {
      animation: homeScreen_transitionIn_sm 0.5s;
      animation-fill-mode: backwards;
      top: 90px;
      opacity: 1;
    }
    .inSection & {
      top: 320px;
      opacity: 0;
      display: none;
    }
  }
}
