#helpBG {
  display: none;
  @include transparentBG($basic, .7);
  @include fixed;
  @include fillSpace;
  z-index: 400;
}
#helpContent {
  z-index: 401;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  @include fillSpace;
  .arrowNav {
    display: block;
  }
}
#helpCarousel {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 0 95px;
  background: $light;
  color: $basic;
  top: -400px;
  @include transition($speed: 1s);
  //border-top: 45px solid $basic;
  &.showing {
    top: 0;
  }
  .carousel-inner {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .item {
    width: 100%;
    margin: 0 auto;
    padding: 0 100px 45px;
    margin-top: 30px;
  }
  .titleWrap {
    background: $primary;
    padding: 15px 0;
    text-align: center;
  }
  h1 {
    text-transform: uppercase;
    color: $light;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.7em;
    font-size: 1.2em;
  }
  h3 {
    @include headerFont;
    font-weight: 600;
    font-size: 1.1em;
    color: $secondary;
    margin: 15px 0 5px;
  }
  .helpSlideTitle {
    font-weight: 600;
    font-size: 1.8em;
    color: $primary;
    font-family: $secondaryFont;
    margin: 15px 0 5px;
  }
  .copy {
    font-family: $basicFont;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.45em;
    color: $basic;
    ul {
      li {
        padding-bottom: 8px;
      }
    }
    p {
      @include zeroMargPad;
      padding-bottom: 10px;
    }
    img {
      max-width: 100%;
      height: auto;
      @include boxShadow();
    }
    h2 {
      font-size: 1.8em;
    }
  }
  .helpArrow {
    position: absolute;
    left: 0;
    width: 100%;
    top: 25%;
    height: auto !important;
    overflow: auto !important;
    overflow: visible !important;
    z-index: 99;
    .arrow {
      top: auto;
      position: absolute;
      width: 50px;
      height: 48px;
      padding: 0 1px 0;
      border-width: 2px;
      border-style: solid;
      border-color: $primary;
      text-decoration: none;
      background-color: $primary;
      color: $light;
      font-size: 2.4em;
      cursor: pointer;
      span {
        position: relative;
        color: $light;
        font-size: 0.8em;
        left: 9px;
        transition: color, .25s;
        z-index: 3;
      }
      &:hover {
        span {
          color: $primary;
        }
        &:before {
          width: 100%;
          height: 100%;
        }
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        background-color: $light;
        transition: width .25s, height .25s;
      }
      &.left {
        left: 5px;
      }
      &.right {
        right: 5px;
      }
    }
  }
  .carousel-indicators {
    bottom: -15px;
    li {
      border: 2px solid $basic;
      &.active {
        background: $basic;
      }
    }
  }
  .helpBtnWrap {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    .lbClosebtn {
      @include btnStyle;
      position: relative;
      background: $primary;
      color: $light;
      border: 2px solid $primary;
      z-index: 1;
      overflow: hidden;
      &:hover {
        color: $primary;
        background: $primary;
        &:before {
          width: 110%;
          height: 110%;
        }
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        background-color: $light;
        transition: width .25s, height .25s;
      }
    }
    &.disabled {
      .lbClosebtn {
        background: $grey;
        color: lighten($dark, 60%);
        &:hover {
          cursor: not-allowed;
          background: none;
        }
      }
    }
    &:hover {
      cursor: pointer;
      &:before {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.inSection {
  .helpBtn {
    display: none;
  }
}
@media (max-width: $sm) {
  #helpContent {
    background: $light;
    top: 0;
  }
  #helpCarousel {
    &.showing {
      top: 0;
    }
    h1 {
      // padding-top: 10px;
    }
  }
}
@media (max-width: $xs) {
  #helpContent {
    top: 0;
    position: absolute;
  }
  #helpCarousel {
    padding-bottom: 160px;
    &::before {
      display: none;
    }
    .helpArrow {
      position: fixed;
      top: auto;
      bottom: 0;
      width: 100%;
      height: 48px !important;
      .arrow {
        width: 50%;
        span {
          position: absolute;
          left: 50%;
          top: 8px;
          transform: translateX(-50%);
          display: block;
        }
        &:hover {
          span {
            color: $light;
          }
        }
        &:before {
          display: none;
        }
      }
    }
    .helpSlideTitle {
      font-size: 1.8em;
    }
    .copy {
      li {
        margin-bottom: 7px;
      }
      ul {
        margin: 0;
      }
      p {
        @include zeroMargPad;
        padding-bottom: 10px;
      }
      img {
        max-width: 100%;
        height: auto;
        @include boxShadow();
      }
      h2 {
        font-size: 1.8em;
      }
    }
    .helpBtnWrap {
      bottom: 80px;
      .lbClosebtn {
        font-size: 1.5em;
      }
      .lbClosebtn {
        @include btnStyle;
        position: relative;
        background: $primary;
        color: $light;
        border: 2px solid $primary;
        z-index: 1;
        overflow: hidden;
        &:hover {
          color: $light;
          background: $primary;
          &:before {
            display: none;
          }
        }
        &:before {
          display: none;
        }
      }
    }
    .item {
      width: 100%;
      padding: 0 20px 45px;
    }
    .helpSlideTitle {
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}
@media (max-width: $xxs) {
  #helpCarousel {
    //padding: 0 0 80px;
    font-size: 0.9em;
    h1 {
      max-height: 100%;
    }
  }
}
