/*-----ADA Link---------------*/
.adaLink{
    @include transition;
    &:hover{
        background:$primary;
        color:$light;
    }
}

.alertMode {
  .adaLink {
    display: none !important;
  }
}
@media (max-width: $md) {
  .adaLink {
    display: none !important;
  }
}
