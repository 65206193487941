#inner-content .arrowNav {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .arrow {
    position: absolute;
    top: 0;
    width: 56px;
    height: 48px;
    padding: 6px 10px 0;
    color: $light;
    font-size: 2.4em;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {}
    }
    span {
      &:before {}
    }
    &:hover {}
  }
  #arrowLeft {
    left: 0;
  }
  #arrowRight {
    right: 0;
  }
}
@media (max-width: 400px) {
  #inner-content .arrowNav {
    .arrow {
      font-size: 1.8em;
      padding: 10px 8px 0;
    }
    #arrowRight {
      text-align: right;
    }
  }
}
