.selfDirList {
	list-style: none;
	clear: both;
	@include zeroMargPad;
	@include clearfix;
	margin: 10px 0 10px 0 !important;
	.selfDirItem {
		margin-left: 0;
		margin-bottom: 10px;
		&:before {
			display: none;
		}
		a {
			//display: inline-block;
			position: relative;
			display: block;
			padding: 5px 20px 5px 50px;
			border: 2px solid $primary;
			font-size: 1.35em;
			color: $primary!important;
			text-decoration: none;
			z-index: 5;
			overflow: hidden;
			&:hover {
				color: $light !important;
				&:before {
	        width: 105%;
	        height: 105%;
	      }
			}
			&:before {
				content: "";
		    display: block;
		    position: absolute;
		  	z-index: -1;
		  	top: 50%;
		  	left: 50%;
		  	transform: translate(-50%,-50%);
		    width: 0;
		    height: 0;
			  background-color: $primary;
		    transition: width .25s,height .25s;
			}
			&:after {
				@include transition;
				@include iconFont;
				content: 'd';
				float: right;
				position: absolute;
				left: 12px;
				top: 8px;
			}
			span {
				position: relative;
				z-index: 1;
				transition: color .25s;
			}
		}
		&.disabled {
			visibilty: hidden;
		}
	}
}
.selfDirTable {
	display: block;
	tbody {
		display: block;
	}
	tr {
		display: block;
	}
	.selfDirCell {
		display: block;
	}
	.selfDirBtn {
		@include button;
	}
	a {
		display: block;
		color: $light;
	}
}
@media (max-width: $xs) {
	.selfDirList .selfDirItem a {
		font-size: 1.05em;
		&:after {
			top: 11px;
		}
	}
}
