.accordionButtonWrapper {
  border-left: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  @include zeroMargPad;
  &:first-child {
    margin-top: 20px !important;
  }
  &.open {
    .accordionButton {
      // background: $secondary;
      cursor: pointer;
      color: $light;
      &:before {
        text-transform: uppercase;
        content: 'u';
        font-family: $iconFont;
        color: $light;
        font-size: 0.8em;
        left: 13px;
        top: 14px;
      }
    }
  }
  p {
    border-left: 0;
    padding-left: 0;
  }
}
.accordionButton {
  font-size: 1.3em;
  font-family: $primaryFont;
  background: $primary;
  font-weight: normal;
  outline: none !important;
  color: $light;
  padding: 10px 0 12px 50px;
  display: block;
  position: relative;
  margin-bottom: 0;
  border: 3px solid $light;
  &:hover {
    cursor: pointer;
    background: $hover;
    color: $light;
    &:before {
      color: $light;
    }
  }
  &:before {
    @include iconFont;
    position: absolute;
    left: 13px;
    top: 15px;
    text-transform: lowercase;
    content: 'q';
    font-family: $iconFont;
    font-size: 0.8em;
    color: $light;
    padding: 3px 10px 3px 0;
  }
}
.accordionWrapper {
  display: none;
  padding: 13px 20px 25px;
  margin-bottom: 3px;
  background: $light;
  p {
    border-left: 0;
    padding-left: 0;
  }
  h3 {
    font-size: 1.15em;
    margin-bottom: 10px;
  }
}
@media (max-width: $xs) {
  .accordionButton {
    font-size: 1em;
    padding: 10px 0 12px 40px;
  }
  .accordionButtonWrapper {
    margin: 0 -10px !important;
  }
  .accordionWrapper {
    margin: 0 -10px 3px !important;
  }
}
