@-ms-viewport{ width: auto !important; }

html { //Shows sidebar at all times
    height: 100vh;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $secondary;
    font-family: $primaryFont;
}

body {
	@include zeroMargPad;
	@include bodyCopy;
    //font-size: 14px;
    background: $secondary;
}
body:before{
    content:""!important;
}

#wrapper{
   // z-index: 1;
    position: relative;
}

#mainContent{
    position: relative;
    height: auto;
    padding: 0;
}
img{border:0;}

.popover {
  font-family: $primaryFont; 
}

a {color: $secondary;}

h1, h2, h3, h4, h5, h6 {font-family: $primaryFont; margin: 0px;}

p{margin: 0px 0px 10px;}
.adaLink{
    @include button($color: $secondary);
	display:block;
	position:fixed;
	bottom:0;
	z-index:10;
	display:block;
	font-size:1.25em;
	border-bottom:0;
	@include transition;
	@media all and (max-width: $xs){
		left:0!important;
		width:100%;
		font-size:1em;
		padding:8px 10px;
		text-align:center;
	}
	&:hover,&:focus{
		background:$hover;
		text-decoration: none;
		color:$light;
	}
    .inSection &{

            display: none;
    }
}

//*****UNIVERSAL*****//

.container-fluid{
	padding:0;
	@media all and (max-width: 1370px){

	}
	@media all and (max-width: $sm){

	}
	@media all and (max-width: $xs){

	}
}

.row{
	margin:0!important;
}
#backgroundCover{
	width:100%;
	height:100%;
	@include fixed;
    //overflow:visible;
    background-image:url(/images/background.jpg);
    @include backgroundCover;
}

@media (max-width: $sm){
    .adaLink{
        width: 100%;
        height: 25px;
        font-size: 1em;
        text-align: center;
        padding: 2px 0px;
    }
}
