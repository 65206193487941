#main-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: opacity 0.3s;
  height: 78px;
  padding: 0 0 0 125px;
  .button {
    position: relative;
    .bgImg {}
    .imgCaption {}
    &.active {
      margin-left: -20px;
      a {
        font-size: 1.3em;
        cursor: pointer;
        color: $light;
        &:before {
          content: '';
        }
        &:after {
          content: '';
          width: 100%;
          height: 3px;
          background-color: $primary;
          position: absolute;
          bottom: -15px;
          pointer-events: none;
          opacity: 0;
          transition: all, 0.3s;
        }
        &:hover {
          &:after {
            content: '';
            bottom: -5px;
            opacity: 1;
          }
        }
        .section-title {}
      }
    }
    &.completed {
      a {
        cursor: pointer;
        &:before {
          content: 'c';
        }
        &:after {
          content: '';
          width: calc(100% + 30px);
          height: 3px;
          background-color: $primary;
          position: absolute;
          bottom: -15px;
          margin-left: -30px;
          pointer-events: none;
          opacity: 0;
          transition: all, 0.3s;
        }
        &:hover {
          &:after {
            content: '';
            bottom: -5px;
            opacity: 1;
          }
        }
        .section-title {}
      }
    }
    a {
      position: relative;
      display: inline-block;
      color: $dark;
      cursor: default;
      text-decoration: none;
      &:before {
        content: 'l';
        position: absolute;
        top: 2px;
        left: -30px;
        font-size: 1.5em;
        font-family: $iconFont;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .section-title {
      display: none;
    }
    .sectionNum {
      display: none;
    }
    .abbr-title {
      font-family: $secondaryFont;
      font-weight: normal;
    }
  }
}
.cloneNavWrap {
  display: none;
}
.navControls {
  display: none;
}
.alertMode {
  #main-nav {
    opacity: 0;
  }
}
@media (max-width: $xl) {
  #main-nav {
    font-size: 0.85em;
    height: 68px;
    padding: 0 0 0 110px;
  }
}
@media (max-width: $lg) {
  #main-nav {
    padding: 0 30px 0 110px;
    height: 62px;
    .button.completed a {
      &:before {
        left: -24px;
      }
      &:after {
        margin-left: -22px !important;
        width: calc(100% + 22px) !important;
      }
    }
  }
}
@media (max-width: $md) {
  #main-nav {
    display: block;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    margin-left: 50px;
    &:hover {
      -ms-overflow-style: -ms-autohiding-scrollbar !important;
    }
    .button {
      display: inline-block;
      vertical-align: middle;
      padding: 15px 35px;
    }
    .button a:before {
      left: -25px;
    }
    .button:first-child {
      margin-left: 20px;
    }
    .button:last-child {
      margin-right: 30px;
    }
  }
  #main-nav::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: $sm) {
  #main-nav {
    /*
    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 90px;
      left: 50px;
      height: 50px;
      width: 25px;
      z-index: 99;
      background: linear-gradient(to right, rgba(36,39,41,1) 0%,rgba(36,39,41,1) 40%,rgba(36,39,41,0) 100%);
      pointer-events: none;
    }
    &:after {
      content: "";
      display: block;
      position: fixed;
      top: 90px;
      right: 0;
      height: 50px;
      width: 80px;
      z-index: 99;
      background: green;
      background: linear-gradient(to right, rgba(36,39,41,0) 0%,rgba(36,39,41,0.7) 60%,rgba(36,39,41,1) 100%);
      pointer-events: none;
    }*/
    .button {
      padding: 15px 28px;
    }
  }
}
