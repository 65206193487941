/**VARIABLES**/
//Colors
$primary: #852332; // Garnet (Red)
$secondary: #242729; // Charcoal
$accent: #d9a762; // Pale Yellow, or Goldenrod if you want to get fancy
$alt: #00abad; //
$contrast: #c12126; //
$link: $secondary;  //
$basic: #444;  //
$hover: $secondary;


$light: #fff;
$dark: #97979a;  // dark grey
$grey: #dad9d7; // light grey

$mainBG: darken($grey,10%); // grey
$bodyBG: $light; //White

$wrong: $primary; // Grey
$correct: #4f7f71; // Green


//Fonts
$primaryFont: 'Roboto', sans-serif;
$secondaryFont: 'Playfair Display', serif;
$basicFont: 'Roboto', sans-serif;
$iconFont: 'shaw';



//OLD Media Query Resolutons
$width-xxsm: 480px;
$width-xsm: 600px;
$width-sm: 800px;
$width-md: 1050px;
$width-lg: 1280px;
$width-xlg: 1500px;

//OLD Media Query Resolutons
$tn : 600px;
$xs : 800px;
$sm : 1050px;
$md : 12800px;
$max : 1500px;


//New Widths
$xxs: 480px;
$xs: 600px;
$sm: 800px;
$md: 1050px;
$lg: 1280px;
$xl: 1500px;


//Sizes
$progress : 20px;
$headerHeight:150px;
$smHeaderHeight:100px;
$footerHeight: 45px;
$infoSize: 50px;
$smHeader: 60px;
$maxWidth: 1400px;

//Elements
$cursor: pointer;
