.videoSlide {
  .videoWrapper {
    padding-right: 0 !important;
  }
  .videoContainer {
  }
  &.vidBelow {
    .content {
      max-width: 1200px;
      margin: 0 auto !important;
    }
    .slideSide {
      width: 100%;
      margin: 0 auto;
      padding: 20px 150px 70px;
      float: none !important;
      border-width: 0px 5px 5px 5px;
      border-color: $secondary;
      border-style: solid;
      background-color: $light;
      .tips {
        display: none;
      }
    }
    .slideCopy {
      width: 100% !important;
      margin: 0 auto !important;
      float: none !important;
      border-width: 5px 5px 0px 5px;
      border-color: $secondary;
      border-style: solid;
      padding: 45px 50px 5px;
      //background: none;
    }
    .videoRTE {
      border: 0px;
      padding: 28px 0 16px;
      background-color: $light;
    }
    #learnMoreWrapper {
      margin: 0 auto;
    }
  }
  .slideSide {
    background-color: $secondary;
  }
  .videoCopy {
    padding: 35px 50px 20px;
  }
  .tips {
    margin-top: 10px;
  }
}
.videoWrapper {
  .loading {
    display: block;
    color: $grey;
    font-size: 1.2em;
    font-family: $primaryFont;
    text-align: center;
    padding-top: 40px;
    text-transform: uppercase;
  }
  .videoRTE {
    border: 2px solid $primary;
    margin-top: 10px;
    padding: 22px 35px 16px;
    background-color: $light;
  }
  .captionTitle {
    position: relative;
    display: inline-block;
    margin-top: 22px;
    margin-bottom: 12px;
    color: $primary;
    font-size: 1.8em;
    font-family: $secondaryFont;
    font-weight: bold;
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: -11px;
      width: 45%;
      height: 3px;
      background-color: $accent;
    }
  }
}
.autoplaySet {
  display: none;
}
//***4:3 RATIO VIDEOS***//
/*#videoHolder {
    position: relative;
    padding-bottom: 75%;//for 4:3 ratio videos
    padding-top: 0;
    height: 0;
    margin-bottom: 15px;

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}*/
//***16:9 RATIO VIDEOS***//
#videoHolder,
.videoHolder {
  position: relative;
  padding-bottom: 56.25%; //for 16:9 ratio videos
  padding-top: 0;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


@media (max-width: $xl) {
  .videoSlide {
    .videoCopy {
      padding: 30px 35px 10px;
    }
  }
}
@media (max-width: $lg) {
  .videoSlide {
    .videoWrapper {
      width: 100%;
    }
    .slideSide {
      flex-wrap: wrap;
    }
    .learnMoreSide {
      margin-top: 10px;
      margin-bottom: 0;
    }
    .tips {
      margin-bottom: 0;
      width: 100%;
    }
  }
}
@media (max-width: $sm) {
  .videoSlide {
    .videoCopy {
      padding: 30px 35px 20px;
    }
  }
  .videoSlide.vidBelow {
    .slideSide {
      padding: 20px 20px 70px;
    }
    .slideCopy {
      padding: 20px 20px 5px;
    }
    #learnMoreWrapper {
      width: 100%;
    }
  }
}
@media (max-width: $xs) {
  .videoSlide {
    .videoCopy {
      padding: 20px 20px 10px;
    }
  }
  .videoWrapper .videoRTE {
    padding: 15px 20px 10px;
  }
}
