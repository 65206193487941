$utilityWidth: 30%;
#homeScreen .utilityBtn {
  position: absolute;
  top: 17px;
  left: 80px;
  height: 40px;
  margin-left: -6px;
  cursor: pointer;
  color: $light;
  transition: all 0.2s;
  overflow: hidden;
  .icon {
    font-size: 2.8em;
    display: inline-block;
  }
  &:hover {
    color: $accent;
  }
  .alertMode & {
    display: none;
  }
}
.safari .utilityMenu.open {
  .utilityBtn {
    display: block;
    margin-left: 0;
    position: absolute;
    left: auto;
    right: 0;
    top: 20px;
    border-width: 2px 0 2px 2px;
  }
}
.utilityMenu {
  position: fixed;
  left: -$utilityWidth;
  width: $utilityWidth;
  top: 0;
  background: $light;
  height: 100%;
  overflow: auto;
  z-index: 231;
  @include transition();
  &.open {
    left: 0;
    .utilityBtn {
      display: block;
      margin-left: 0;
    }
  }
  .utilityBtn {
    display: none;
    position: fixed;
    top: 17px;
    left: $utilityWidth;
    height: 47px;
    width: 65px;
    margin-left: -6px;
    cursor: pointer;
    background: $primary;
    color: $light;
    border-width: 2px 2px 2px 0;
    border-style: solid;
    border-color: $light;
    transition: all 0.2s;
    overflow: hidden;
    z-index: 1;
    .icon {
      font-size: 1.45em;
      display: inline-block;
      margin: 9px 0 0 23px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      background-color: $light;
      transition: width 0.25s, height 0.25s;
    }
    &:hover {
      color: $primary;
      &:before {
        width: 105%;
        height: 105%;
      }
    }
  }
  .menuContent {
    @include clearfix;
    // margin:20px 0;
    // margin-left:40px;
    border-bottom: 2px solid $grey;
    padding: 25px 0;
    h1 {
      font-weight: 300;
      text-transform: none;
      color: $primary;
      font-size: 2.4em;
    }
    &.UMinfo {
      padding: 15px;
      font-family: $primaryFont;
      //text-align: right;
      border-bottom: 0;
      .UMtitle {
        font-weight: 300;
        text-transform: none;
        color: $primary;
        font-size: 2.4em;
      }
      .UMstudent {
        color: $dark;
        font-weight: 600;
      }
    }
    &.UMbtns {
      // margin:0px 12px 0 12px;
      padding: 25px 15px;
      background: $grey;
    }
    &.toc {
      margin-left: 0;
      padding-left: 0;
      background: $light;
      h1 {
        padding-left: 15px;
        font-size: 3em;
        font-family: $secondaryFont;
      }
    }
    .btn {
      @include button;
      display: block;
      margin: 0;
      display: inline-block;
      float: left;
      font-size: 1.1em;
      margin-right: 10px;
      text-align: center;
      cursor: pointer;
      text-decoration: none !important;
    }
    .certBtn {
      // display:none;
      float: left;
      clear: both;
      margin-top: 10px;
      padding-left: 30px;
      background: $light!important;
      border-color: $primary;
      color: $primary!important;
      position: relative;
      .icon-cert {
        @include absolute;
        top: 4px;
        left: 8px;
        font-size: 1.25em;
      }
      &:hover {
        background: $hover;
        border-color: $light;
        color: $light;
      }
    }
    .orientationComplete & {
      .certBtn {
        display: inline-block;
      }
    }
  }
}
//*****TOC******//
#sitemap {
  // font-size:1.25em;
  font-family: $primaryFont;
  ul {
    list-style: none;
    padding-left: 0;
    ul {
      li {
        position: relative;
        &:hover {
          .lockedMsg {
            display: block;
          }
          li {
            .lockedMsg {
              display: none;
            }
          }
        }
        &.level-4 {
          &:hover {
            .lockedMsg {
              display: block!important;
              z-index: 9999;
            }
          }
        }
      }
      ul {
        //padding-left:40px;
      }
    }
  }
  .level-2 {
    @include zeroMargPad;
    div {
      display: none;
    }
    a {
      //text-transform:uppercase;
      font-size: 1.2em;
      color: $light;
      background: $primary;
      padding: 5px 10px 5px 20px;
      cursor: default;
      margin-bottom: 5px;
    }
  }
  .level-3 {
    &.selfDir {
      a {
        // border:2px solid $grey;
        // margin-left:20px;
        font-weight: bold;
      }
    }
    a {
      text-transform: none;
      font-size: 1em;
      color: $primary;
      background: transparent;
      padding: 7px 10px 7px 20px;
      cursor: pointer;
      position: relative;
      color: $primary;
      position: relative;
      text-decoration: none !important;
      border-bottom: 1px $grey solid;
      &:hover {
        background: $light;
        color: $secondary;
        &:before {
          font-family: $iconFont;
          content: 'd';
          @include absolute;
          left: 2px;
          top: 50%;
          margin-top: -10px;
        }
      }
    }
  }
  .level-4 {
    a {
      margin-left: 20px;
      padding: 10px 10px 10px 20px;
      //background:darken($light,30%);
      font-weight: bold!important;
      border: 0;
      border-bottom: 1px solid $grey !important;
      color: $primary;
      cursor: default;
      &:before {
        display: none;
      }
      &:hover {
        color: $primary;
      }
    }
  }
  .level-5 {
    a {
      cursor: pointer;
      color: $primary;
      background-color: $light;
      font-weight: normal!important;
      &:before {
        display: block;
      }
      &:hover {
        color: $secondary;
      }
    }
  }
  a {
    text-decoration: none!important;
    display: block;
    //@include transition;
  }
  .locked {
    a {
      cursor: default;
    }
    .level-3 {
      a {
        opacity: 0.5;
      }
    }
  }
  .lockedMsg {
    display: none;
    @include absolute;
    width: 100%;
    top: 0;
    background: $dark;
    color: $light;
    font-weight: 700;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    z-index: 1;
    height: 100%;
    cursor: not-allowed;
  }
}
@media (max-width: $xl) {
  #homeScreen .utilityBtn {
    top: 14px;
  }
  .utilityMenu {
    width: 35%;
    left: -35%;
    .utilityBtn {
      left: 35%;
    }
  }
}
@media(max-width: $lg) {
  #homeScreen .utilityBtn {
    left: 23px;
    top: 10px;
  }
  .utilityMenu {
    width: 40%;
    left: -40%;
    .utilityBtn {
      left: 40%;
    }
  }
}
@media(max-width: $md) {
  .utilityMenu {
    width: 100%;
    left: -100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .utilityBtn {
      position: absolute;
      left: auto;
      right: 0;
      top: 20px;
      border-width: 2px 0 2px 2px;
      &:hover {
        background-color: $secondary;
        color: $light;
        &:before {
          display: none;
        }
      }
    }
  }
}
@media (max-width: $xxs) {
  .utilityMenu.open .menuContent.UMbtns {
    padding: 20px 15px 10px!important;
    .btn {
      //display: block;
      margin-bottom: 12px;
      padding: 9px 15px;
      max-width: calc(100% - 64px);
      float: none !important;
      float: none !important;
      display: block !important;
      margin-right: 0 !important;
    }
  }
  .utilityMenu.open {
    .menuContent.toc {
      padding-top: 12px;
      h1 {
        font-size: 2em;
      }
    }
    .utilityBtn {
      top: 52px;
      height: 90px;
      padding-top: 23px;
    }
  }
}
