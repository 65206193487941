.slide.quizSlide {
  .videoWrapper {
    //margin:0;
  }
  .question {
    padding-bottom: 10px;
  }
}
.quizCopy {}
.quizButtons {
  @include zeroMargPad;
  list-style: none;
  margin: 0 -5px;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  li {
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    flex-basis: 48%;
    flex-grow: 1;
    margin: 5px;
    position: relative;
    //min-height: 65px;
    padding: 18px 5px 18px 0;
    border: 2px solid $secondary;
    color: $secondary;
    cursor: pointer;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      transition: width 0.7s, height 0.7s;
    }
  }
  p {
    margin: 0 0 0 50px !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    line-height: 1.3em;
    transition: color .4s;
  }
  .letterWrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px;
    font-size: 1.8em;
    overflow: hidden;
  }
  span.letter {
    position: absolute;
    z-index: 2;
    font-size: 1em;
    transition: color .4s;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
  .picked {
    color: $light;
    border: 2px solid $wrong;
    &:before {
      width: 105%;
      height: 115%;
      background-color: $wrong;
    }
    span.letter {
      color: $light;
    }
  }
  li.correct.picked {
    color: $light;
    //background: $correct;
    border: 2px solid $correct;
    &:before {
      width: 105%;
      height: 115%;
      background-color: $correct;
    }
  }
}
.answersWrapper {
  @include clearfix;
  color: $light;
  font-size: 1.35em;
  padding: 20px 35px;
}
.quizContent {
  color: $light;
  padding: 0;
  p {
    line-height: 1.25em;
    color: $basic;
    width: auto;
    font-size: 1.1em;
    position: relative;
    padding-bottom: 10px;
    &.resp {
      display: none;
      font-size: 1em;
      padding: 5px 10px;
      color: $light;
      &:before {
        @include iconFont;
        color: $light;
        margin-right: 4px;
      }
    }
    &.answerWrong {
      color: $light;
      background-color: $wrong;
      padding: 5px;
      display: none;
    }
    &.answerCorrect {
      color: $light;
      background-color: $correct;
      padding: 5px;
      display: none;
    }
    //        &.question {
    //            padding:20px 0;
    //            color:$basic;
    //            font-size:1em;
    //        }
    //        &.answerCorrect{
    //            display:none;
    //            background:$correct;
    //            font-size:1em;
    //        }
  }
  //    p.answerWrong{
  //        background-color: $wrong;
  //        display:none;
  //        background:$wrong;
  //        font-size:1em;
  //    }
}
@media (max-width: $lg) {
  .quizButtons {
    display: block;
    margin-top: 0 !important;
    .li {
      margin: 10px 5px;
    }
    p {
      top: 0;
      transform: none;
    }
  }
  .quizSlide .slideSide {
    .videoWrapper {
      width: 100%;
    }
  }
}
