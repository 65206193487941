header {
	position: fixed;
	z-index: 101;
	width: 100%;
	height: $headerHeight;
	padding: 0 0 0 80px;
	background-color: $primary;
	animation: none;
	.logoWrap {
		display: inline-block;
		height: 100%;
	}
	.logo {
		height: 100%;
		width: auto;
		padding: 32px 0;
	}
	.currentSectionTitle {
		margin: 0 0 0 38px;
		font-family: $secondaryFont;
		color: $light;
		font-size: 2.6em;
		display: none;
		vertical-align: middle;
		animation: fadeIn 0.3s;
	}
	.menuBtn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 57px;
		color: $light;
		text-align: center;
		font-size: 2.5em;
		transition: transform 0.3s;
		display: none;
		animation: fadeIn 0.3s; 
		span {
			display: inline-block;
			padding-top: 2px;
		}
		&:hover {
			cursor: $cursor;
			color: $light;
			transform: translateY(-50%) scale(1.2);
		}
	}
	.transitionOut & {
		animation: header_transitionOut 0.6s;
		animation-delay: 0.5s;
		animation-fill-mode: backwards;
		padding: 0 32px;
	}
	.transitionIn & {
		animation: header_transitionIn 0.6s;
		animation-fill-mode: backwards;
		padding: 0 80px;
	}
	.inSection & {
		padding: 0 32px;
		.logo {
			cursor: pointer;
		}
		.menuBtn {
			display: block;
		}
		.currentSectionTitle {
			//display: inline-block;
		}
	}
}
.alertMode {
	header {}
}
@media all and (max-width: $xl) {
	header {
		height: 120px;
		.logo {
			padding: 22px 0;
		}
	}
}
@media (max-width: $lg) {
	header {
		padding: 0 0 0 25px;
		animation: none;
		.transitionIn & {
			animation: none;
			padding: 0 0 0 25px;
		}
		.transitionOut & {
			animation: none;
			padding: 0 0 0 25px;
		}
		.inSection & {
			padding: 0 0 0 25px;
		}
	}
}
@media (max-width: $sm) {
	header {
		height: 90px;
		position: fixed;
		.currentSectionTitle {
			font-size: 1.8em;
			margin: 0 0 3px 25px;
			position: absolute;
			top: 25px;
			left: 120px;
		}
	}
	.inSection {
		header {
			transition: all 0.2s;
			.logoWrap {
				transition: opacity 0.2s;
			}
			.currentSectionTitle {
				transition: opacity 0.2s;
			}
			.menuBtn {
				right: 30px;
				transition: opacity transform 0.2s;
			}
		}
		header.minimize {
			//background: $accent;
			overflow: hidden;
			height: 4px;
			.logoWrap {
				opacity: 0;
			}
			.currentSectionTitle {
				opacity: 0;
			}
			.menuBtn {
				opacity: 0;
			}
		}
	}
}
@media (max-width: $xs) {
	header {
		.menuBtn {
			font-size: 2em;
			right: 28px;
		}
		.currentSectionTitle {
			font-size: 1.4em;
			margin: 0 0 3px 15px;
			top: 30px;
			left: 125px;
		}
	}
}
@media (max-width: $xxs) {
	header {
		animation: none;
		padding: 0 0 0 20px;
		.logo {
			padding: 25px 0;
		}
		.menuBtn {
			font-size: 1.8em;
			right: 20px !important;
		}
		.currentSectionTitle {
			font-size: 1.2em;
			margin: 0 0 3px 15px;
			top: 33px;
			left: 110px;
		}
		.transitionIn & {
			animation: none;
			padding: 0 0 0 20px;
		}
		.transitionOut & {
			animation: none;
			padding: 0 0 0 20px;
		}
		.inSection & {
			padding: 0 0 0 20px;
		}
	}
}
