.alertBar {
  position: fixed;
  z-index: 110;
  top: 0;
  right: 80px;
  width: 300px;
  height: $headerHeight;
  display: flex;
  // Vertical alignment
  align-items: center;
  // Horizontal alignment
  justify-content: space-between; 
  animation: none;
  .item {
    position: relative;
    color: $light;
    transition: transform .3s;
    cursor: pointer;
    .icon {
      font-size: 2.4em;
      position: relative;
    }
    &:hover {
      transform: scale(1.2);
    }
  }
  .notify {}
  .alertName {
    display: none;
  }
  .transitionOut & {
    animation: alertBar_transitionOut .5s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
    opacity: 0;
    right: 0;
  }
  .transitionIn & {
    animation: alertBar_transitionIn .5s;
    animation-fill-mode: backwards;
    opacity: 1;
    right: 80px;
  }
  .inSection & {
    opacity: 0;
    right: 0;
    display: none;
  }
}
.searchMode {
  .alertBar {
    display: none;
  }
}
//ALERT CONTENT AREAS//
#alertContentWrap {
  display: none;
  position: fixed;
  z-index: 230;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 160px);
  height: calc(100% - 150px);
  overflow: auto !important;
  background-color: $light;
  &.open {}
  .alertContent {
    display: none;
    padding: 0;
  }
  h1 {
    font-family: $secondaryFont;
    font-weight: normal;
    color: $light;
    font-size: 2em;
    padding: 20px 40px 19px 80px;
    margin-bottom: 15px;
    height: 78px;
    background: $secondary;
    text-align: left;
  }
  .alertInnerContent {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
  }
  .alertInstruction {
    padding: 20px;
    margin-right: 20px;
    background-color: $primary;
    color: $light;
    width: 30%;
    display: flex;
flex-flow: column;
justify-content: center;
max-height: 280px;
    h2 {
      font-family: $secondaryFont;
      font-size: 3em;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.4em;
    }
  }
  .alertListCopy {
    flex: auto;
  }
  #checklist {
    .checkContent {
      display: flex;
      justify-content: space-between;
    }
    .list {
      list-style: none;
      padding-left: 35px;
    }
    li {
      font-family: $basicFont;
      border-bottom: 1px solid $grey;
      padding: 15px 0 15px 30px;
      color: $basic;
      position: relative;
      @include clearfix;
      &:before {
        @include pseudoContent;
        height: 15px;
        width: 15px;
        border: 2px solid $basic;
        @include absolute;
        top: 20px;
        left: 5px;
        line-height: 0.5em;
        font-size: 1.2em;
        text-align: center;
        text-transform: none;
      }
      a {
        position: relative;
        padding: 10px 14px 10px 12px;
        border: 2px solid $primary;
        float: left;
        margin: 10px 0;
        text-transform: none;
        color: $primary;
        overflow: hidden;
        transition: color .25s;
        &:before {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0;
          height: 0;
          background-color: $primary;
          transition: width .25s, height .25s;
        }
        &:hover {
          //color: $light;
          text-decoration: none!important;
          color: $light;
          &:before {
            width: 105%;
            height: 105%;
          }
        }
        &:after {
          font-family: $iconFont;
          content: 'c';
          display: block;
          float: left;
          margin-right: 5px;
        }
      }
      &.checked {
        &:before {
          content: 'c';
          font-family: $iconFont !important;
          text-align: center;
          line-height: 0.3em;
        }
        h3 {
          cursor: default;
        }
      }
    }
    .instruction {
      font-family: $basicFont;
      font-size: 1.25em;
      line-height: 1.7em;
      background: $primary;
      padding: 5px 10px 5px 40px;
      color: $light;
      @media all and (max-width: $xs) {
        font-size: 1.2em;
        line-height: 1.35em;
      }
      p {
        @include zeroMargPad;
      }
    }
    h3 {
      font-family: $basicFont;
      font-size: 1.3em;
      font-weight: 400;
      text-transform: none;
      padding-bottom: 5px;
      margin-left: -26px;
      padding-left: 26px;
      cursor: pointer;
      position: relative;
    }
    .due {
      font-weight: 800;
      text-transform: uppercase;
      font-size: 0.9em;
      font-family: $basicFont;
      display: block;
      margin: 5px 0;
      clear: both;
      &.noDate {
        display: none;
      }
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  #announcements {
    .announcement {
      border-bottom: 1px solid $grey;
      padding: 20px 0;
      margin-right: 40px;
      font-family: $secondaryFont;
      font-size: 1.125em;
      @media all and (max-width: $xs) {
        margin-right: 20px;
      }
      &.new {
        background-color: darken($light,20%);
        /* h2{
                    font-family: $secondaryFont;
                    color: $light;
                }
                p{
                    font-family:$basicFont;
                    color: $basic;
                } */
      }
      h2 {
        font-family: $secondaryFont;
        font-size: 1.3em;
        padding: 3px 20px;
        color: $basic;
        &:before {
          font-family: $iconFont;
          content: 'a';
          margin-left: -10px;
          margin-right: 10px;
          text-transform: none;
          color: $basic;
        }
      }
      p {
        @include zeroMargPad;
        padding: 5px 20px 0 40px;
        color: $basic;
        font-family: $primaryFont;
        line-height: 1.7em;
      }
    }
  }
  #account {
    .accountContent {
      @include clearfix;
      margin-left: 40px;
      @media all and (max-width: $xs) {
        margin-left: 10px;
      }
      h2 {
        font-family: $secondaryFont;
        font-size: 1.5em;
        font-weight: 400;
        color: lighten($secondary,25%);
        float: left;
        margin-right: 20px;
        @media all and (max-width: $xs) {
          margin: 0 20px 20px 0;
        }
        span {
          font-weight: 700;
          color: $basic;
        }
      }
      a {
        @include btnStyle;
        margin: 0;
        display: block;
        float: left;
      }
    }
  }
  .learnMoreContent {
    padding-top: 0 !important;
  }
  #resources {
    .slide {
      margin-top: 0;
    }
    h1 {
    }
    h3 {
      font-family: $secondaryFont;
      color: $primary;
      margin: 20px 0 10px;
      font-size: 1.5em;
    }
    h4 {
      //display: none;
    }
    .slideTitle {
      display: none;
    }
    .textCopy {
      padding: 0 60px 0 0;
    }
  }
  .closeBtn {
    display: none;
    position: absolute;
    width: 75px;
    height: 78px;
    top: 0;
    left: 0;
    padding: 29px 20px 8px;
    color: $light;
    font-size: 1.5em;
    text-align: center;
    &:hover {
      cursor: pointer;
      color: $accent;
    }
    &.show {
      display: block;
    }
  }
}
#allowChecked {
  display: none;
  position: fixed;
  top: 20%;
  left: 50%;
  width: 300px;
  margin: 0 0 0 -150px;
  background: $light;
  padding: 35px;
  z-index: 9999;
  color: $basic;
  text-align: center;
  h1 {
    color: $primary;
    background: transparent;
    margin-bottom: 15px;
    font-size: 2.5em;
    font-family: $secondaryFont;
  }
  span {
    position: relative;
    display: inline-block;
    padding: 8px 14px 8px 12px;
    border: 2px solid $primary;
    margin: 5px 0;
    text-transform: none;
    color: $primary;
    overflow: hidden;
    transition: color .25s;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      background-color: $primary;
      transition: width .25s, height .25s;
    }
    &:hover {
      color: $light;
      &:before {
        width: 110%;
        height: 110%;
      }
    }
  }
  p {
    color: $secondary;
  }
}
#hiddenOverlay {
  display: none;
  @include fixed;
  @include fillSpace;
  z-index: 210;
  @include transparentBG(#000, .85);
}
#wrapper.noOverflow {
  overflow: hidden;
}
@media all and (max-width: $xl) {
	.alertBar {
    height: 120px;
  }
  #alertContentWrap {
    height: calc(100% - 120px);
    h1 {
      height: 68px;
      padding: 14px 40px 19px 80px;
    }
    .closeBtn {
      padding: 24px 20px 8px;
    }
  }
}
@media (max-width: $lg) {
  #alertContentWrap {
    height: calc(100% - 120px);
    width: 100%;
    h1 {
      height: 62px;
      padding: 10px 0px 19px 80px;
    }
    .closeBtn {
      padding: 20px 20px 8px;
    }
  }
  .alertBar {
    right: 30px;
    width: 270px;
    .item .icon {
      font-size: 2em;
    }
    .transitionOut & {
      animation: alertBar_transitionOut_lg .5s;
      animation-delay: 0.5s;
      animation-fill-mode: backwards;
      opacity: 0;
      right: -40px;
    }
    .transitionIn & {
      animation: alertBar_transitionIn_lg .5s;
      animation-fill-mode: backwards;
      opacity: 1;
      right: 30px;
    }
  }
}
@media (max-width: $md) {
  #alertContentWrap {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    .alertInnerContent {
      display: block;
    }
    #checklist .checkContent {
      display: block;
      margin: 0 auto;
    }
    #checklist .list {
      padding-left: 0px;
    }
    #announcements {
      .announcement {
        margin-right: 0;
        margin-top: 10px;
      }
    }
    .alertInstruction {
      display: block;
      width: 100%;
      h2 {
        font-size: 2em;
      }
      p {
        font-size: 1.1em;
        margin-bottom: 0 !important;
      }
    }
  }
}
@media (max-width: $sm) {
  #alertContentWrap {
    height: calc(100% - 90px);
    .alertInnerContent {
      padding: 0 15px;
    }
  }
  .alertBar {
    height: 90px;
    width: 250px;
  }
}
@media (max-width: $xs) {
  #alertContentWrap {
    h1 {
      margin-bottom: 5px;
      padding: 10px 0 19px 65px;
    }
    .closeBtn {
      width: 65px;
    }
    #announcements {
      .announcement p {
        font-size: 0.95em;
        line-height: 1.6em;
        padding: 5px 0 0 40px;
      }
    }
    #resources h3 {
      margin: 20px 0 0;
    }
    .alertInnerContent {
      padding: 0 5px;
    }
  }
  .alertBar {
    width: 200px;
    .item .icon {
      font-size: 1.7em;
    }
  }
}
@media (max-width: $xxs) {
  .alertBar {
    width: 190px;
    right: 15px;
    .item .icon {
      font-size: 1.7em;
    }
    .transitionOut & {
      animation: alertBar_transitionOut_xxs .5s;
      animation-delay: 0.5s;
      animation-fill-mode: backwards;
      opacity: 0;
      right: -40px;
    }
    .transitionIn & {
      animation: alertBar_transitionIn_xxs .5s;
      animation-fill-mode: backwards;
      opacity: 1;
      right: 15px;
    }
  }
}
@media (max-width: 420px) {
  .alertBar {
    width: 140px;
    .item .icon {
      font-size: 1.5em;
    }
  }
}
