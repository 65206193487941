#inner-content {
	//important
	position: relative;
	display: none;
	top: $headerHeight;
	z-index: 100;
	background-color: $secondary;
	.slideHolder {
		//important
		@include absolute;
		width: 100%;
		min-height: 500px;
		display: none;
	}
}
.errorPage .sectionBGs {
	display: block;
	#button1BG {
		display: block;
	}
}
.content {
	font-family: $basicFont;
	font-size: 1.125em;
	line-height: 1.85em;
	font-weight: 400;
	padding-bottom: 50px;
}
.slideTitle {
	font-family: $secondaryFont;
	color: $primary;
	text-align: left;
	font-size: 2.3em;
	margin-bottom: 25px;
}
.slide {
	padding: 30px 30px 40px;
	.table-responsive {
		@media all and (max-width: $width-sm) {
			border: 2px solid lighten($dark,10%);
			&:before {
				content: 'Swipe from left to right to view the entire table of information.';
				display: block;
				background: lighten($dark,10%);
				padding: 5px;
				text-align: center;
				font-size: 0.85em;
				color: white;
			}
		}
		td {
			border-top: 0;
		}
	}
}
.slideCopy {
	padding: 40px 50px 45px;
	border: 5px solid $secondary;
	background-color: $light;
	transition: min-height .2s;
	ul {
		margin-top: -10px;
	}
	li {
		position: relative;
		margin-bottom: 7px;
		font-family: $primaryFont;
		list-style-type: none;
		&:before {
			content: "";
			display: block;
			position: absolute;
			left: -18px;
    	top: 12px;
			width: 4px;
			height: 4px;
			background-color: $primary;
		}
	}
}
.slideSide {
	border: 5px solid $secondary;
	//background-color: $light;
}
.imgWrap {
	margin: 0 auto;
	max-height: 800px;
	overflow: hidden;
	img {
		//max-width: 100%;
		width: 100%;
		height: auto;
	}
	.multiImage:last-child {
		display: none;
	}
	.multiImage:first-child {
		display: block;
	}
}
.copySlide {
	.slideSide {
		//display: flex;
		//flex-flow: column;
		.imgWrap {
			//flex: auto;
			//border-bottom: 10px solid $secondary;
			//border: 2px solid $light;
		}
	}
}
//Text Highlight based on School Colors
/*
::selection {
    background: $primary;
    color:$light;
}
::-moz-selection {
    background: $primary;
    color:$light;
}
*/
/*
.selfDirSlide .content {
	display: flex;
	//align-items: stretch;
	//align-items: flex-start;
  //justify-content: space-between;
	.slideSide {
		flex: 1 1 45%;
		.imgWrap img {
			//width: 100%;
		}
	}
	.slideCopy {
		flex: 1 1 55%;
	}
}*/

.single-column {
	float: none !important;
	margin: 0 auto !important;
}

@media (max-width: $xl) {
	.slide {
		padding: 30px 15px 50px;
		li {
			margin-bottom: 2px;
			font-size: 0.98em;
		}
	}
	.slideCopy {
		padding: 25px 30px 15px;
	}
	.slideTitle {
		margin-bottom: 25px;
		font-size: 2em;
	}
}
@media (max-width: $xl) {
	#inner-content {
		top: 120px;
	}
}

@media (max-width: $lg) {
	.slideCopy {
		max-width: 700px;
		min-height: 0 !important;
		margin: 0 auto;
		padding: 30px 35px 10px;
	}
	.slideSide {
		max-width: 700px;
		min-height: 0 !important;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap-reverse;
	}
	.slide {
		padding: 15px 20px 50px;
	}
}
@media (max-width: $sm) {
	#inner-content {
		top: 90px;
	}
	.slide {
		padding: 5px 5px 50px;
	}
	.slideCopy, .slideSide {
		max-width: 800px;
	}
	.slideCopy {
		padding: 30px 35px 20px;
	}
}
@media (max-width: $xs) {
	.slideTitle {
		margin-bottom: 15px;
		font-size: 1.8em;
	}
	.slideCopy {
		padding: 20px 20px 10px;
	}
}
