.loginPage {
  .error {
    color: lighten($wrong, 20%);
    font-weight: bold;
    font-weight: 600;
    font-size: 0.85em;
    display: block;
    margin-top: -1px;
  }
  header {
    position: relative;
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    top: 0;
    padding: 0 70px;
    .title {
      position: absolute;
      right: 70px;
      top: 50%;
      transform: translateY(-50%);
      h1 {
        color: $light;
        font-family: $secondaryFont;
        font-weight: normal;
        font-size: 2.8em;
      }
    }
  }
}
#membership {
  margin-top: 25px;
  .titleArea {
    background: $primary;
    color: $light;
    max-width: 850px;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 10px;
    p {
      @include zeroMargPad;
      font-size: 1.1em;
    }
    a {
      color: $accent;
      font-weight: bold;
    }
    h1 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.9em;
      line-height: 1.3em;
      display: block;
    }
  }
  .loginError {
    ul {
      list-style-type: none;
      padding: 0;
      text-align: center;
    }
  }
}
.formWrap {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  //@include transparentBG($light, .85);
  background: $light;
  @include clearfix;
  padding: 25px 0 35px;
  position: relative;
  &.login {
    display: table;
    padding: 0;
    background: transparent;
    h1 {
      padding-bottom: 10px;
    }
    .fieldWrapper {
      float: none;
      width: 100%;
      label {
        display: none;
      }
    }
    .utility {
      position: static;
    }
  }
  &.userAccount {
    form {
      margin: 0 auto;
      @include clearfix;
    }
  }
  &.password {
    .fieldWrapper {
      float: none;
    }
    .Submit {
      width: 75%;
      margin: 0 auto;
      text-align: center;
      padding-right: 0;
    }
    .message {
      font-size: 1.25em;
      text-align: center;
      color: $primary;
      margin: 10px 0;
    }
  }
  h1 {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.8em;
    text-transform: uppercase;
    padding-bottom: 20px;
    text-align: center;
    color: $primary;
  }
  p {
    font-family: $basicFont;
    font-weight: 600;
    font-size: 1em;
    text-align: center;
  }
  button {
    background: $secondary;
    text-decoration: none;
    font-size: 1em;
    font-family: $primaryFont;
    font-weight: 400;
    display: inline-block;
    padding: 7px 45px;
    color: $light;
    text-transform: uppercase;
    margin-top: 10px;
    border: 0;
    box-shadow: none;
    @include rounded(0);
    &:hover {
      color: $light;
      background: $hover;
    }
  }
}
.formArea {
  padding: 25px 15px;
  p {
    margin-bottom: 20px;
    margin-top: 0;
  }
}
.returningStudentWrapper {
  width: 52%;
  @include borderBox;
  display: table-cell;
  vertical-align: middle;
  //@include transparentBG($light, .85);
  background: $light;
  color: $primary;
  padding: 25px 40px;
  border-left: 5px solid $secondary;
  input {
    text-align: center;
  }
}
.newStudentWrapper {
  width: 48%;
  @include borderBox;
  background: $light;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  color: $primary;
  text-align: center;
  border-right: 5px solid $secondary;
  h1 {
    margin-top: -40px;
    color: $primary;
  }
  .account {
    margin-top: 0;
  }
}
.account {
  a {
    @include button($color: $secondary);
    margin-top: 10px;
    padding: 10px 25px 10px 55px;
    font-size: 1em;
    min-width: 250px;
    &:before {
      @include pseudoContent;
      @include absolute;
      content: 'k';
      @include iconFont;
      left: 10px;
      text-align: left;
      top: 6px;
      font-size: 1.8em;
      font-weight: normal;
      text-transform: none;
    }
  }
}
.logError {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  background: $light;
  text-align: center;
  padding: 15px;
  h1 {
    text-transform: uppercase;
    color: $primary;
  }
  .error {
    color: $basic;
    font-size: 1.2em;
  }
}
.Message {
  text-align: center;
  padding-bottom: 25px;
}
.fieldWrapper {
  @include clearfix;
  width: 60%;
  margin: 0 auto 10px;
  //float: left;
  padding: 0 15px;
  @include borderBox;
  label {
    font-family: $primaryFont;
    color: $basic;
    //display: none;
    font-weight: 700;
    text-transform: uppercase;
  }
  input,
  select {
    margin-bottom: 5px;
    padding: 9px 7px;
    font-size: 1.3em;
    font-family: $primaryFont;
    box-shadow: none;
    width: 100%;
    border: 1px solid $primary;
    &.input-validation-error {
      background: lighten($wrong, 55%);
      //border: 1px solid $wrong !important;
    }
  }
}
.Submit {
  margin: 0 auto;
  text-align: right;
  padding-right: 10px;
}
.utility {
  @include absolute;
  top: auto;
  bottom: 10px;
  left: 25px;
  a {
    color: $link;
    font-size: 0.95em;
    text-decoration: underline !important;
    margin-right: 10px;
    &:hover {
      text-decoration: none !important;
    }
  }
}
.ie8,
.ie9 {
  .formWrap {
    label {
      display: block !important;
    }
  }
}
@media (max-width: $md) {
  .loginPage {
    header {
      padding: 0 25px;
      .title {
        right: 25px;
      }
      .title h1 {
        font-size: 2.5em;
      }
    }
    #membership {
      margin: 10px 0;
      padding-bottom: 20px;
    }
    #membership .titleArea {
      max-width: 650px;
      border-width: 0 10px 0 10px;
      border-style: solid;
      border-color: $secondary;
      //display: none;
    }
    .formWrap {
      display: block;
      max-width: 650px;
      border-width: 0 10px 0 10px;
      border-style: solid;
      border-color: $secondary;
      .newStudentWrapper {
        display: block;
        width: 100%;
        border-width: 0 0 0 0;
        border-style: solid;
        border-color: $secondary;
      }
      .newStudentWrapper h1 {
        margin: 0;
      }
      .newStudentWrapper .account {
        //margin-top: 0px;
      }
      .returningStudentWrapper {
        display: block;
        width: 100%;
        border-width: 10px 0 0 0;
        border-style: solid;
        border-color: $secondary;
      }
    }
    .userAccount {
      padding-top: 0;
    }
    .password {
      padding-top: 0;
    }
  }
}
@media (max-width: $sm) {
  .loginPage {
    .fieldWrapper {
      width: 80%;
    }
  }
}
@media (max-width: $xs) {
  .loginPage {
    header {
      .title h1 {
        font-size: 2em;
      }
    }
  }
}
@media (max-width: $xxs) {
  .loginPage {
    header {
      padding: 0 15px;
      .title {
        right: 15px;
      }
      .title h1 {
        font-size: 1.5em;
      }
    }
    .formWrap.login {
      .returningStudentWrapper {
        padding: 25px 20px;
      }
    }
    .fieldWrapper {
      width: 100%;
      padding: 0 5px;
    }
  }
}
