.progressBar {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.progress {
  font-family: $primaryFont;
  font-size: 1.5em;
  text-transform: none; 
  color: $light;
}
.timer {
  position: fixed;
  z-index: 99;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: darken($primary, 10%);
  .inner {
    display: block;
    position: relative;
    top: 0;
    width: 0;
    height: 100%;
    background-color: $primary;
  }
}

@media (max-width: $xs) {
  .progressBar {
    text-align: center;
    min-width: 500px;
    z-index: 98;
  }
  .progress {
    font-size: 1em;
  }
}
