#backgroundImg {
	position: relative;
	flex: auto;
	div {
		@include backgroundCover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: 50% 50%;
		display: none;
	}
}
.backgroundButtons {
	position: fixed;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 0;
	white-space: nowrap;
	animation: none;
	.beginSectionButton,
	.welcomeButton {
		display: inline-block;
		min-height: 210px;
		margin: 0 9px;
		vertical-align: middle;
		font-size: 14px;
	}
	.transitionOut & {
		animation: backgroundButtons_transitionOut 1s;
		animation-fill-mode: backwards;
		bottom: -500px;
	}
	.transitionIn & {
		animation: backgroundButtons_transitionIn 1s;
		animation-fill-mode: backwards;
	}
	.inSection & {
		bottom: -500px;
		display: none;
	}
}
.beginSectionButton {
	width: 440px;
	padding: 55px 40px 30px;
	background-color: $primary;
	color: $light;
	cursor: pointer;
	&:hover {
		.sectionName:before {
			width: 45%;
		}
		.icon {
			left: 14px;
		}
	}
	&.congratulatory {
		cursor: auto;
		.sectionName:before {}
		.icon {
			display: none;
		}
	}
	&.congratulatory:hover {
		.sectionName:before {
			width: 27%;
		}
	}
	.sectionName {
		position: relative;
		font-family: $secondaryFont;
		font-size: 3.1em;
		font-weight: normal;
		line-height: 1.4em;
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -2px;
			width: 27%;
			height: 3px;
			background-color: $accent;
			transition: width 0.4s;
		}
	}
	.sectionNumber {
		font-family: $primaryFont;
		font-size: 2em;
		font-weight: normal;
		text-transform: uppercase;
	}
	.icon {
		position: relative;
		bottom: -4px;
		left: 18px;
		font-size: 2em;
		transition: left 0.4s;
	}
}
.welcomeButton {
	width: 410px;
	padding: 50px 40px 20px;
	background-color: $light;
	.student {
		h3 {
			position: relative;
			margin-top: 6px;
			font-family: $primaryFont;
			font-size: 2.7em;
			font-weight: 500;
			line-height: 1.3em;
			color: $primary;
			span {
				display: block;
				margin-top: -9px;
				font-size: 0.9em;
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: -8px;
				width: 27%;
				height: 3px;
				background-color: $primary;
				transition: width 0.4s;
			}
		}
		p {
			font-family: $primaryFont;
			font-size: 1.2em;
		}
	}
}

@media (max-width: $xl) {
	.backgroundButtons {
		.beginSectionButton, .welcomeButton {
			min-height: 180px;
		}
		.beginSectionButton {
			font-size: 11px;
			width: 360px;
			padding: 50px 40px 20px;
			.sectionName:before {
				top: -3px;
			}
		}
		.welcomeButton {
			font-size: 12px;
			width: 360px;
			padding: 42px 40px 20px;
		}
	}
}
@media (max-width: $sm) {
	.backgroundButtons {
		.welcomeButton {
			display: none;
		}
		.beginSectionButton {
			width: 440px;
			padding: 50px 40px 30px;
			min-height: 200px;
			margin: 0;
			font-size: 14px;
		}
	}
}
@media (max-width: $xs) {
	.backgroundButtons {
		bottom: 20px;
		.beginSectionButton {
			font-size: 11px;
			width: auto;
			min-height: 150px;
			padding: 40px 70px 20px 40px;
			.sectionName:before {
				top: -3px;
			}
		}
		.transitionOut & {
			animation: backgroundButtons_transitionOut_xs 1s;
			animation-fill-mode: backwards;
			bottom: -500px;
		}
		.transitionIn & {
			animation: backgroundButtons_transitionIn_xs 0.7s;
			animation-fill-mode: backwards;
		}
		.inSection & {
			bottom: -500px;
			display: none;
		}
	}
}
