.inSection{
    .rspBtn{
        display: none;
    }
}
.rspBtn{
    position: fixed;
    top: 12px;
    right: 15px;
    border: 1px solid $primary;
    z-index: 230;
    color: $primary;
    padding: 3px 7px 3px 27px;
    @include transition();
    display: none;
    overflow: hidden;
    .title{
        font-family: $primaryFont;
        text-transform: uppercase;
        font-size: .9em;
        @include transition();
    }
    .icon{
        font-size: 1.1em;
        @include absolute;
        top: 4px;
        left: 5px;
        @include transition();
        &.icon-menu{
            font-size: 1.2em;
        }
        &.icon-x{
            top: 5px;
        }
        &.hidden{
            left: -10px;
        }
    }
    &:hover{
        background: $secondary;
       // color: $light;
        cursor: pointer;
        border-color: $secondary;
        &.open{
            border-color: $secondary;
        }
    }
    &.open{
       // background: $secondary;
        color: $light;
        border: 1px solid $light;
        .title{
            content: 'Close';
        }
    }
    
}

#offCanvasPanel{
    position: fixed;
    right: -600px;
    width: 400px;
    top: 0px;
    z-index: 225;
    background: $light;
    @include boxShadow();
    height: 100%;
    @include transition();
    color: $light;
      overflow: auto;
    &.open{
        right: 0px;
    }
    .student{
        background: $primary;
        padding: 50px 15px 10px 15px;
        
        .name{
//            display: block;
        }
        h3{
            text-align: left;
            color: $light;
        }
    }
    #clone-nav{
        background: $light;
        .button{
            float: none;
            padding: 10px 15px;
            border-bottom: 1px solid $accent;

            &:hover{
               a{ color: $secondary; display: block;}
            }
        }
        h2{
            font-size: 2em;
            padding-left: 5px;
        }
    }
    .utlBtns{
        list-style-type: none;
        @include zeroMargPad;
        @include clearfix;
        li{
            
            float: left;
            width: 50%;
            @include borderBox;
            text-align: center;
            &.first{
                border-right: 1px solid darken($accent, 10%);
            }
            
        }
        a{
            display: block;
            padding: 10px 0px;
            background: $accent;
            text-transform: uppercase;
            font-weight: 700;
            font-size: .85em;
            font-family: $basicFont;
            &:hover{
                background: $secondary;
                color: $primary;
            }
        }
    }
}
@media (max-width: $width-sm){
    .rspBtn{
        display: block;
    }
}
@media (max-width: $width-xxsm){
    .rspBtn{
        top: 8px;
        right: 8px;
          padding: 5px 5px 0px;
        .title{
            display: none;
        }
        .icon{
            position: static;
        }
    }
    #offCanvasPanel{
        width: 100%;
        .student{
            .name{
                display: block;
            }
        }
        .utlBtns{
            li{
                float: none;
                width: 100%;
                &.first{border-right: 0px;border-bottom: 1px solid darken($accent, 10%);}
            }
        }
    }
}