/* Each keyframe is roughly in the same order as it's
being fired for each ANIMATION CHAIN */

/* MENU OUT */
@keyframes backgroundButtons_transitionOut {
  0% {bottom: 40px;opacity: 1;}
  30% {bottom: 60px;opacity: 1;}
  60% {opacity: 0;}
  100% {bottom: -300px;opacity: 0;}
}
@keyframes homeScreen_transitionOut {
  0% {top: 150px;opacity: 1;}
  100% {top: 350px;opacity: 0;}
}
@keyframes alertBar_transitionOut {
    0%   {opacity: 1; right: 80px;}
    100%  {opacity: 0; right: 0px;}
}
@keyframes header_transitionOut {
    0% {padding: 0 80px;}
    100% {padding: 0 32px;}
}

/* MENU OUT XL */
@keyframes homeScreen_transitionOut_xl {
  0% {top: 120px;opacity: 1;}
  100% {top: 280px;opacity: 0;}
}

/* MENU OUT LG */
@keyframes alertBar_transitionOut_lg {
    0%   {opacity: 1; right: 30px;}
    100%  {opacity: 0; right: -40px;}
}

/* MENU OUT SM */
@keyframes homeScreen_transitionOut_sm {
  0% {top: 90px;opacity: 1;}
  100% {top: 180px;opacity: 0;}
}

/* MENU OUT XS */
@keyframes backgroundButtons_transitionOut_xs {
  0% {bottom: 20px;opacity: 1;}
  30% {bottom: 50px;opacity: 1;}
  60% {opacity: 0;}
  100% {bottom: -300px;opacity: 0;}
}

/* MENU OUT XXS */
@keyframes alertBar_transitionOut_xxs {
    0%   {opacity: 1; right: 15px;}
    100%  {opacity: 0; right: -40px;}
}

/* MENU IN */
@keyframes backgroundButtons_transitionIn {
  0% {bottom: -300px;opacity: 0;}
  30% {opacity: 0;}
  60% {bottom: 60px;opacity: 1;}
  100% {bottom: 40px;opacity: 1;}
}
@keyframes homeScreen_transitionIn {
  0% {top: 350px;opacity: 0;}
  100% {top: 150px;opacity: 1;}
}
@keyframes alertBar_transitionIn {
    0%  {opacity: 0; right: 0px;}
    100%   {opacity: 1; right: 80px;}
}
@keyframes header_transitionIn {
    0% {padding: 0 32px;}
    100% {padding: 0 80px;}
}
/* MENU IN XL */
@keyframes homeScreen_transitionIn_xl {
  0% {top: 320px;opacity: 0;}
  100% {top: 120px;opacity: 1;}
}

/* MENU IN LG */
@keyframes alertBar_transitionIn_lg {
    0%  {opacity: 0; right: -40px;}
    100%   {opacity: 1; right: 30px;}
}
/* MENU IN SM */
@keyframes homeScreen_transitionIn_sm {
  0% {top: 180px;opacity: 0;}
  100% {top: 90px;opacity: 1;}
}

/* MENU IN XS */
@keyframes backgroundButtons_transitionIn_xs {
  0% {bottom: -300px;opacity: 0;}
  /*30% {opacity: 0;}
  60% {bottom: 50px;opacity: 1;}*/
  100% {bottom: 20px;opacity: 1;}
}

/* MENU IN XXS */
@keyframes alertBar_transitionIn_xxs {
    0%  {opacity: 0; right: -40px;}
    100%   {opacity: 1; right: 15px;}
}

/* GENERIC ANIMATIONS */
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
