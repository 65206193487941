@charset "UTF-8";

@font-face {
  font-family: "shaw";
  src:url("../fonts/shaw.eot");
  src:url("../fonts/shaw.eot?#iefix") format("embedded-opentype"),
    url("../fonts/shaw.woff") format("woff"),
    url("../fonts/shaw.ttf") format("truetype"),
    url("../fonts/shaw.svg#shaw") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "shaw" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "shaw" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-announce:before {
  content: "\61";
}
.icon-link:before {
  content: "\72";
}
.icon-search:before {
  content: "\66";
}
.icon-check:before {
  content: "\63";
}
.icon-arrow-left:before {
  content: "\62";
}
.icon-arrow-right:before {
  content: "\64";
}
.icon-menu:before {
  content: "\6d";
}
.icon-lock:before {
  content: "\6c";
}
.icon-home:before {
  content: "\68";
}
.icon-accordion-open:before {
  content: "\71";
}
.icon-accordion-close:before {
  content: "\75";
}
.icon-learn-more:before {
  content: "\69";
}
.icon-x:before {
  content: "\78";
}
.icon-quickview:before {
  content: "\65";
}
.icon-slideview:before {
  content: "\74";
}
.icon-account:before {
  content: "\6b";
}
.icon-pdf19:before {
  content: "\70";
}

@charset "UTF-8";

@font-face {
  font-family: "shaw";
  src:url("../fonts/shaw.eot");
  src:url("../fonts/shaw.eot?#iefix") format("embedded-opentype"),
    url("../fonts/shaw.woff") format("woff"),
    url("../fonts/shaw.ttf") format("truetype"),
    url("../fonts/shaw.svg#shaw") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "shaw" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "shaw" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-announce:before {
  content: "\61";
}
.icon-link:before {
  content: "\72";
}
.icon-search:before {
  content: "\66";
}
.icon-check:before {
  content: "\63";
}
.icon-arrow-left:before {
  content: "\62";
}
.icon-arrow-right:before {
  content: "\64";
}
.icon-menu:before {
  content: "\6d";
}
.icon-lock:before {
  content: "\6c";
}
.icon-home:before {
  content: "\68";
}
.icon-accordion-open:before {
  content: "\71";
}
.icon-accordion-close:before {
  content: "\75";
}
.icon-learn-more:before {
  content: "\69";
}
.icon-x:before {
  content: "\78";
}
.icon-quickview:before {
  content: "\65";
}
.icon-slideview:before {
  content: "\74";
}
.icon-account:before {
  content: "\6b";
}
.icon-pdf19:before {
  content: "\70";
}
