.tips {
  background: $light;
  padding: 22px 35px 18px;
  margin-top: 10px;
  color: $light;
  position: relative;
  border: 2px solid $primary;
  &.smTip {
    display: none;
  }
  .tipTitle {
    position: relative;
    display: inline-block;
    margin-top: 22px;
    margin-bottom: 12px;
    color: $primary;
    font-size: 1.8em;
    font-family: $secondaryFont;
    font-weight: bold;
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: -11px;
      width: 45%;
      height: 3px;
      background-color: $accent;

    }
  }
  .icon {
    position: absolute;
    top: -15px;
    left: -15px;
    border-radius: 50%;
    font-size: 1.8em;
    padding: 10px;
    background: $primary;
    color: $light;
    height: 45px;
    width: 45px;
    text-align: center;
  }
  .tip {
    padding: 0;
    color: $basic;
    font-family: $primaryFont;
  }
}
@media (max-width: $lg) {
  .tips {
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}
@media (max-width: $xs) {
  .tips {
    padding: 15px 20px 5px;
  }
}
