#umbracoPreviewBadge {
  position: fixed!important;
  top: 0 !important;
  left: auto!important;
  width: 100%!important;
  height: auto!important;
  background: #bacb0f!important;
  display: block!important;
  padding: 0 4px 10px;
  color: black;
  text-align: center;
  max-height: 80px;
  overflow: auto;
  &:after {
    display: block;
    font-size: 1em;
    margin-top: 5px;
    content: "PREVIEW MODE: This is a preview, and should be used for content evaluation only. Some features and styles may not act/look exactly the way they do on live site.";
  }
  &:hover {
    background: lighten(#bacb0f,10%)!important;
  }
}
$top: 35px;
.PreviewMode {
  #wrapper {
    top: $top;
  }
  header {
    top: $top;
  }
  #homeScreen {
    top: 185px;
    height: calc(100% - 185px);
  }
  #learnMoreWrapper {
    display: none !important;
  }
  #inner-content {
  //  display: block !important;
  }
  .alertBar {
    top: $top;
  }
  #backgroundImg {
    div:last-child {
      display: block !important;
    }
  }
  .utilityMenu {
    top: $top;
    height: calc(100% - #{$top});
  }
  .utilityBtn.open {
    top: 17px + $top;
  }
  .slideBody {
    margin-top: $top + 75px;
    position: relative;
    .timer {
      background: $secondary;
    }
  }
  .adaBtn {
    display: none !important;
  }
  .menuBtn {
    display: none;
  }
}
.inSection .PreviewMode {
  top: $top;
}
@media (max-width: $xl) {
  .PreviewMode {
  #umbracoPreviewBadge {
    display: none !important;
  }
  #wrapper {
    top: 0;
  }
  header {
    top: 0;
  }
  }
}
