.slide {
  p {
    @include zeroMargPad;
    margin-bottom: 15px;
    font-family: $primaryFont;
  }
  /*
  .intro {
    font-family: $primaryFont;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.45em;
    line-height: 1.7em;
  }
  .sub-head {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.45em;
    line-height: 1.7em;
  }*/
  h2 {
    font-family: $primaryFont;
    color: $primary;
    margin-bottom: 8px;
    font-size: 1.8em;
  }
  h3 {
    font-family: $primaryFont;
    color: $primary;
    margin-top: 11px;
    margin-bottom: 6px;
    font-size: 1.4em;
    line-height: 1.3em;
  }
  .listHeading {
    margin-top: -2px;
    margin-bottom: 20px;
    font-size: 1.15em;
    line-height: 1.2em;
    color: $secondary;
    font-weight: bold;
  }
  /*
  .highlight {
    background: $grey;
    padding: 7px;
    display: block;
  }
  .blockText {
    background: $secondary;
    display: block;
    padding: 15px 20px;
    color: $light;
    line-height: 1.6em;
    font-style: italic;
    margin-top: 45px;
  }*/
  @media (max-width: $xl) {
    p {
      font-size: 0.98em;
      line-height: 1.7em;
      margin-bottom: 12px;
    }
  }
  @media (max-width: $xs) {
    h2 {
      font-size: 1.5em;
      margin-bottom: 4px;
    }
  }
}
