.spinner{
	display:none;
	position: fixed!important;
	left: 50%!important;
	top: 50%!important;
	transform: translate(-50%, -50%);
	div{
		div{
			background:$accent!important;
		}
	}
}
